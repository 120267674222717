const getSlug = (name: string) => {
  if (!name) {
    return "product";
  }

  const fullSlug = name
    .toLowerCase()
    .trim()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

  return fullSlug.substring(0, 50);
};

export const getMenuItemUrl = (
  menuItem: {
    dispensarySlug: string;
    name: string;
    menuItemId: string | number;
    variants?: Array<{ id: string | number }>;
  },
  selectedVariantId?: number,
) => {
  const variantId =
    selectedVariantId || (menuItem.variants && menuItem.variants[0].id);

  return `/dispensary-info/${menuItem.dispensarySlug}/p/${
    menuItem.menuItemId
  }/${getSlug(menuItem.name)}${variantId ? `?variant=${variantId}` : ""}`;
};
