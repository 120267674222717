import React, { ReactNode, useMemo } from "react";
import classnames from "classnames";
import capitalize from "lodash/capitalize";
import Link from "next/link";

import { DISPENSARY_PLACEHOLDER_PHOTO } from "constants/dispensary";
import { Dispensary } from "custom-types/Dispensary";
import { MenuItem } from "custom-types/MenuItem";
import { MenuItemV2 } from "custom-types/MenuItemV2";
import { VariantWithDeliveryInfo } from "custom-types/Variant";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import currencyFormatter from "utils/currencyFormatter";
import { getDistance } from "utils/distance";
import { formatQuantity } from "utils/formatQuantity";
import { getMenuItemUrl } from "utils/menuItemUrl";

import AdFlag from "components/botanic/AdFlag";
import CardFlag from "components/botanic/Card/CardFlag";
import StarRating from "components/botanic/StarRating";
import Verified from "components/Icons/verified.svg";
import Image from "components/Image";
import SmartOfferCard from "components/SmartOfferCard";

type Props = {
  className?: string;
  handleCardClick?: React.MouseEventHandler<HTMLAnchorElement>;
  /**
   * Show featured flag
   * @default false
   */
  isFeatured?: boolean;
  menuItem: MenuItem | MenuItemV2 | VariantWithDeliveryInfo;
  /**
   * Pass an element used for order specific actions like add to bag
   */
  children?: ReactNode;
  /**
   * Pass an element used for rendering fulfillment UI component
   */
  renderFulfillmentInfo?: (dispensary: Dispensary) => void;
  /**
   * Show dispensary information
   */
  showFulfillment?: boolean;
  /**
   * Show similar strain flag
   */
  similarStrain?: boolean;
  /**
   *Displays single row layout menu card
   */
  menuCardIndicators?:
    | {
        cardFlagText: string | undefined;
        showStaffPick: boolean | undefined;
      }
    | undefined;
};

const MenuItemCard: React.FC<Props> = ({
  children,
  className,
  handleCardClick = () => {},
  isFeatured = false,
  menuItem,
  menuCardIndicators,
  menuItem: {
    brandName,
    cbdContentLabel,
    deal,
    dispensary,
    formattedThumbnailUrl,
    id,
    name,
    offers,
    onlineFulfillmentEnabled,
    price,
    product,
    productCategory,
    quantity,
    strain,
    thcContentLabel,
    unit,
  },
  renderFulfillmentInfo = () => {},
  showFulfillment = false,
  similarStrain = false,
}) => {
  const {
    discountAmount,
    discountedPrice,
    active: dealActive,
    discountLabel,
  } = deal || {};

  const {
    logoUrl,
    pickupEnabled,
    deliveryEnabled,
    id: dispensaryId,
    distanceMi,
    name: dispensaryName,
  } = dispensary || {};
  const showDeal = dealActive && discountAmount && discountedPrice;
  const showOffers = !showDeal && (offers?.length || 0) > 0;
  const promoted = "promoted" in menuItem ? !!menuItem.promoted : false;
  const flagIsShown =
    promoted ||
    !!menuCardIndicators?.cardFlagText ||
    isFeatured ||
    similarStrain;

  const menuItemUrl = getMenuItemUrl(menuItem, id);
  const phenotype = strain?.phenotype;

  const productTypeText =
    productCategory === "Flower" && phenotype
      ? `${phenotype} ${productCategory}`
      : productCategory;

  // Unit and price display
  const displayQuantityFormatted =
    unit &&
    quantity &&
    formatQuantity({
      size: quantity,
      unit,
    });
  const displayPrice = showDeal ? discountedPrice / 100 : price;

  const dispensaryImage = logoUrl || DISPENSARY_PLACEHOLDER_PHOTO;

  const dispensaryMethod = useMemo(() => {
    if (showFulfillment && dispensaryId) {
      if (onlineFulfillmentEnabled) {
        // Dispensary has pickup and delivery enabled
        if (pickupEnabled && deliveryEnabled) {
          return "Pickup or delivery ";
          // Dispensary only has delivery enabled
        } else if (deliveryEnabled) {
          return "Delivery only ";
        } else {
          // Dispensary only has pickup enabled
          return "Pickup ";
        }
      } else {
        return "In-store only ";
      }
    }
  }, [
    pickupEnabled,
    deliveryEnabled,
    dispensaryId,
    onlineFulfillmentEnabled,
    showFulfillment,
  ]);

  const countryCode = useDomainCountryCode();

  const dispensaryDistance =
    showFulfillment &&
    distanceMi !== null &&
    distanceMi !== undefined &&
    `${getDistance(distanceMi, countryCode)} away`;

  return (
    <div
      className={classnames(
        "flex flex-col justify-between relative h-full w-full bg-white shadow-low rounded p-lg overflow-y-hidden",
        {
          "pt-xl": flagIsShown,
        },
        className,
      )}
      data-testid="menu-item-card"
    >
      <Link
        className="block h-full w-full"
        href={menuItemUrl}
        onClick={handleCardClick}
        data-testid="menu-item-card-link"
      >
        <CardFlag
          isFeatured={isFeatured}
          similarStrain={similarStrain}
          menuCardIndicators={menuCardIndicators}
        />
        <AdFlag promoted={promoted} />
        <div>
          <div className="mx-auto mb-md w-[112px]">
            <Image
              src={formattedThumbnailUrl}
              alt={name}
              sizes={[112]}
              ratio="square"
              data-testid="menu-item-card__image"
            />
          </div>
        </div>
        <div className="text-secondary text-xs leading-none">
          {capitalize(productTypeText)}
        </div>
        {name && (
          <h3 className="font-bold text-sm break-words mt-xs min-h-[48px]">
            {name}
          </h3>
        )}
        {brandName && (
          <div className="flex">
            <h4 className="font-normal text-xs">by {brandName}</h4>
            {("isBrandVerified" in menuItem
              ? !!menuItem.isBrandVerified
              : false) && (
              <span className="ml-xs" style={{ paddingTop: "3px" }}>
                <Verified width="14" height="14" className="text-verified" />
              </span>
            )}
          </div>
        )}
        <div className="min-h-[24px]">
          {(thcContentLabel || cbdContentLabel) && (
            <div className="flex flex-row font-bold text-xs mt-xs">
              {thcContentLabel && (
                <div className="mr-sm">{`THC ${thcContentLabel}`}</div>
              )}
              {cbdContentLabel && <div>{`CBD ${cbdContentLabel}`}</div>}
            </div>
          )}
        </div>

        {product?.countApprovedPublicReviews > 0 && (
          <StarRating
            rating={product.avgApprovedPublicReviewRating}
            ratingCount={product.countApprovedPublicReviews}
            showRating={true}
            starWidth={14}
            data-testid="menu-item-card__rating"
            className="mt-xs"
          />
        )}
      </Link>
      <div>
        <Link
          className={classnames("mt-sm", { "block mb-sm": !!children })}
          href={menuItemUrl}
          onClick={handleCardClick}
          data-testid="menu-item-card-link"
        >
          <div className="font-bold text-xs">{displayQuantityFormatted}</div>

          <div
            className={classnames("font-bold text-lg min-h-[24px]", {
              "text-notification": showDeal,
            })}
            data-testid="menu-item-card__price"
          >
            {`${currencyFormatter(displayPrice, countryCode)}`}
          </div>

          {showDeal && (
            <div
              data-testid="discount-details"
              className="flex items-center text-xs mt-xs min-h-[24px]"
            >
              <div className="bg-notification rounded text-white font-bold mr-sm px-sm">
                {discountLabel}
              </div>
              <span>{`reg ${currencyFormatter(price, countryCode)}`}</span>
            </div>
          )}

          {showOffers && offers?.length && (
            <div className="mt-xs">
              <SmartOfferCard offers={offers} abridged />
            </div>
          )}

          {showFulfillment && (
            <div
              className="flex gap-2 mt-xs"
              data-testid="menu-item-card__fulfillment"
            >
              <div
                className="flex-shrink-0 border rounded border-grey-44"
                style={{ height: "24px", width: "24px" }}
              >
                <Image
                  src={dispensaryImage}
                  alt={dispensaryName}
                  sizes={[24]}
                  className="rounded"
                />
              </div>

              <div className="flex flex-col truncate font-bold text-xs">
                <div className="truncate">{dispensaryName}</div>
                <span
                  className={classnames("truncate", {
                    "font-normal": !onlineFulfillmentEnabled,
                    "text-charcoal": onlineFulfillmentEnabled,
                  })}
                >
                  {dispensaryMethod}
                  {dispensaryDistance}
                </span>
              </div>
            </div>
          )}

          {renderFulfillmentInfo(dispensary as Dispensary)}
        </Link>
        {children}
      </div>
    </div>
  );
};

export default MenuItemCard;
